/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "focus-visible"

export const onClientEntry = () => {
  window.onload = () => {
    var doc = document.documentElement

    // MacOS - sets the scrollbar width on load
    // so that a correct "100vw" value can be achieved:
    // calc(100vw - var(--scrollbar-width))
    if ("setProperty" in doc.style) {
      doc.style.setProperty(
        "--scrollbar-width",
        window.innerWidth - doc.clientWidth + "px"
      )
    }
  }
}

export const onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV !== "production") {
    return null
  }

  const pagePath = location
    ? location.pathname + location.search + location.hash
    : undefined
  setTimeout(() => {
    if (typeof window.gtag === "function") {
      window.gtag("event", "page_view", { page_path: pagePath })
    }
  }, 100)
}
